.balances-modal-box {
  .rodal-dialog {
    width: 637px !important;
    height: 561px !important;
    border-radius: 15px;
    background-color: #ffffff !important;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    overflow-x: hidden;

    @media screen and (max-width: 1699px){
      width: 625px !important;
      height: 530px !important;
    }

    @media screen and (max-width: 1599px){
      width: 600px !important;
      height: 500px !important;
    }

    @media screen and (max-width: 1024px) {
      width: 95%!important;
    }
  }

  .rodal-close{
    width: 41px;
    height: 41px;
  }

  .rodal-close:before, .rodal-close:after{
    background: #000000;
  }

  .balances_modal-wrapper{
    height: 100%;

    .header-wallet{
      text-align: center;
      color: #D69700;
      padding: 33px 0 20px 0;
      border-bottom: 1px solid rgba(153, 153, 153, 0.51);
      h4{
        font-size: 30px;
        margin: 0;

        @media screen and (max-width: 1699px){
          font-size: 28px;
        }

        @media screen and (max-width: 1599px){
          font-size: 24px;
        }
      }
    }

    .balance-content{
      padding: 0 30px;
      overflow: auto;
      height: 295px;

      @media screen and (max-width: 1699px){
        padding: 0 20px;
      }

      @media screen and (max-width: 1599px){
        padding: 0 10px;
      }
      .balances-item{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        width: 574px;
        height: 118px;
        padding: 13px 36px 15px 36px;
        margin-top: 20px;
        box-sizing: border-box;

        .balances-item_left{

          .amount{
            font-size: 32px;
            @media screen and (max-width: 1699px){
              font-size: 28px;
            }

            @media screen and (max-width: 1599px){
              font-size: 24px;
            }
          }

          .usd{
            font-size: 22px;
            color: #25292F;

            @media screen and (max-width: 1699px){
              font-size: 18px;
            }

            @media screen and (max-width: 1599px){
              font-size: 16px;
            }
          }

        }

        .balances-item_right{
          display: flex;
          align-items: center;
          .symbol-img{
            width: 47px;
            height: 47px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .symbol{
            font-size: 32px;

            @media screen and (max-width: 1699px){
              font-size: 28px;
            }

            @media screen and (max-width: 1599px){
              font-size: 24px;
            }
          }
        }
      }

      .balances-item.available{
        background: #D6970021 0 0 no-repeat padding-box;
        border: 6px solid #D69700;
        border-radius: 20px;
        padding: 7px 30px 8px 30px;
      }
    }
  }

  .balances_modal-buttons{
    margin: 0 auto;
    text-align: center;

    .MuiButton-label{
      font-size: 25px;
      @media screen and (max-width: 1699px){
        font-size: 22px;
      }

      @media screen and (max-width: 1599px){
        font-size: 18px;
      }
    }

    .custom-button, .border-custom-button{
      width: 208px;
      height: 70px!important;
    }
    .withdraw-btn{
      color: rgb(141, 155, 191);
    }
  }
}