$main-light-color: #D69700 ;
$secondary-dark-color: #A08D4E;
$button-text-color: #F7FAFC;
$button-text-hover-color: black;
$border-color: rgb(196, 188, 159);
$dark-block-color: #151618;

$heading-dark-color: #1B1C22;

$main-font-family: 'SairaRegular', sans-serif;
$main-title-family: 'SairaBold', sans-serif;
$light-white-color: #F7FAFC;
