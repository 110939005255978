@import "../../../../../node_modules/placeholder-loading/src/scss/placeholder-loading";

.placeholder-loading-item-wrapper {
  @media screen and (max-width: 1200px) {
    margin: 0 auto;
  }

  .ph-item {
    width: 250px;
    height: 400px;
    border-radius: 20px;
    padding: 0;

    .ph-picture {
      width: 100%;
      height: 100%;
    }
  }

  .ph-item > * {
    padding: 0;
    margin-bottom: 0;
  }
}
