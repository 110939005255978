@import "../../styles/_variables";

.terms-and-condition-modal-box {
  .rodal-dialog {
    width: 1024px!important;
    height: 600px!important;
    border-radius: 15px;

    @media screen and (max-width: 1024px) {
      width: 95%!important;
    }
  }
}

.terms-and-condition-modal-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  width: 100%;
  height: 100%;

  h1, h3 {
    font-family: $main-title-family;
  }

  p {
    font-family: $main-font-family;
    font-size: 16px;

    span {
      font-family: $main-title-family;
    }
  }

  .terms-and-condition-modal-rules {
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow: auto;

    @media screen and (max-width: 1024px) {
      margin-bottom: 10px;
    }

    .rules-block {
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 10px;
      }
    }
  }

  .confirm-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
    }

    .Mui-checked {
      color: $main-light-color;
    }

    .MuiIconButton-colorSecondary:hover {
      background-color: rgba(214, 151, 0, 0.1);
    }

    .check-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        margin-left: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
