@import "../../styles/_variables";

.loader-wrapper {
  margin: 0 auto;
  width: 60px;
  height: 30px;
  text-align: center;
  font-size: 10px;

  div {
    height: 100%;
    width: 8px;
    display: inline-block;
    float: left;
    margin-left: 2px;
    -webkit-animation: delay 1s infinite ease-in-out;
    animation: delay 1s infinite ease-in-out;
  }

  .bar1 {
    background-color: #A08D4E;
  }

  .bar2 {
    background-color: #DCAA10;
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .bar3 {
    background-color: #D69700;
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  .bar4 {
    background-color: #DCAA10;
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  .bar5 {
    background-color: #D69700;
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  .bar6 {
    background-color: #A08D4E;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
}

@-webkit-keyframes delay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.05) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes delay {
  0%, 40%, 100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
