.imx-order-item {
  width: 100%;
  display: flex;
  padding: 30px 0 50px;
  border-bottom: 2px solid #edeeee;
  justify-content: space-evenly;

  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .lazyload-wrapper {
    margin: initial;
  }

  .item-wrapper {
    box-shadow: 0 5px 25px rgba(0, 0, 0, .1);
  }

  .bought-sold-item-img {
    width: 300px;
    display: flex;
    height: 400px;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
      max-height: 100%;
    }
  }

  .bought-sold-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid #edeeee;
    border-radius: 20px;
    padding: 20px;
    width: 350px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, .1);
    background-color: #f7fafc;

    @media screen and (max-width: 768px) {
      margin-top: 50px;
    }

    .bought-sold-item-info-desc-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      border-bottom: 1px solid #edeeee;
      padding-bottom: 5px;

      &:last-child {
        border-bottom: none;
      }

      p {
        font-size: 18px;
        font-weight: 600;

        &:last-child {
          color: #D69700;
        }
      }
    }
  }
}
