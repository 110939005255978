.slider {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 20px;

  .main-slide {
    width: 100%;
    height: 612px;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .prev-small,
  .next-small {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    z-index: 2;
    cursor: pointer;

    //&:hover {
    //  background-color: rgba(0, 0, 0, 0.3);
    //}
  }

  .prev-small {
    left: -25px;
    color: #151618;
  }

  .next-small {
    right: 25px;
    color: #151618;
  }

  .small-slides {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    //overflow: hidden;
    width: 100%;

    .slides {
      overflow: hidden;
      margin: 0 5px;
      width: 390px;

      .slide-wrapper {
        display: flex;
        justify-content: center;
        transition: transform 0.3s ease;
      }

      .slide {
        flex: 0 0 59px;
        width: 59px;
        height: 103px;
        margin-right: 5px;
        cursor: pointer;
        opacity: 0.6;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &.active {
          opacity: 1;
        }
      }
    }
  }
}
