@import "../../../styles/_variables";

.blockchain-filter-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;

  .MuiPaper-root {
    background: $light-white-color!important;
  }

  .MuiAccordionSummary-content{
    align-items: center;
    margin: 0;

    img{
      width: 30px;
    }
  }

  .blockchain-filter_container{
    background-color: #f4f3f0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    z-index: 2;
    border-radius: 10px;
    padding: 10px 20px;

    h4{
      margin: 0;
      color: rgba(136, 116, 116, 0.55);
      font-size: 14px;
    }

    .blockchain-list{

      margin-top: 10px;
      .blockchain-item{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        p{
          margin: 0;
          font-size: 15px;
          font-weight: 600;
          color: #151618;
        }

        img{
          margin-right: 8px;
          width: 40px;
        }
      }
    }
  }

  .token-filter_container{
    background-color: #f4f3f0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100px;
    z-index: 2;
    border-radius: 10px;
    padding: 10px 20px;

    h4{
      margin: 0;
      color: rgba(136, 116, 116, 0.55);
      font-size: 14px;
    }

    .token-list{

      margin-top: 10px;
      .token-item{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        p{
          margin: 0;
          font-size: 15px;
          font-weight: 600;
          color: #151618;
        }

        img{
          margin-right: 8px;
          width: 40px;
        }
      }
    }
  }

  .token{
    margin-top: 16px;
  }
}
