@import "./../../styles/_variables";

.custom-select-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  p{
    font-weight: bold;
    font-size: 16px;
  }

  @media screen and (max-width: 400px) {
    justify-content: center;
  }

  .custom-button {
      border-radius: 20px;
      margin-right: 20px;
      height: 35px !important;

    @media screen and (max-width: 400px) {
      margin-bottom: 20px;
      margin-right: 0;
      width: 200px;
    }
  }

  .MuiFormControl-root {
    width: 200px;
  }

  .MuiOutlinedInput-root {
    border-radius: 20px;
  }

  .MuiSelect-icon {
    top: auto;
    color: $secondary-dark-color!important;
  }

  .MuiSelect-iconOutlined {
    right: 0;
  }

  .MuiSvgIcon-root {
    font-size: 2rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $secondary-dark-color!important;
  }

  select {
    padding: 8px 10px 8px 15px;
    font-weight: bold;
  }
}
