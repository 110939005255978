@import "../../styles/_variables";

.navigation-wrapper {
  background-color: #151618;
  font-family: $main-font-family;
  font-weight: bold;

  @media screen and (max-width: 1024px) {
    padding: 20px;
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  .navigation-menu {
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid gray;

    @media screen and (max-width: 1024px) {
      overflow: hidden;
      justify-content: flex-start;
      height: 350px;
      border-bottom: none;
    }
  }

  .navigation-logo {
    align-self: center;
    padding: 10px 40px 10px 10px;
    border-right: 1px solid gray;

    img {
      height: 40px;

      &:hover {
        cursor: pointer;
      }
    }

    @media screen and (max-width: 1024px) {
      border-right: none;
      margin-top: 20px;
      padding-left: 25px;
      align-self: flex-start;
    }
  }

  .navigation-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    color: $main-light-color;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: initial;
      padding-bottom: 15px;
    }

    a {
      color: $main-light-color;
    }

    p {
      margin-right: 30px;
      font-weight: bold;
      font-size: 19px;

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
        font-size: 20px;
      }
    }

    .disabled-navigation-option {
      &:hover {
        cursor: initial;
      }
    }
  }

  .mobile-nav-icon {
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
      background: url("../../resources/images/mobile-nav-icon-28.webp");
      width: 60px;
      height: 60px;
      background-size: 100%;
    }
  }

  .menu-closed {
    @media screen and (max-width: 1024px) {
      height: 0;
      display: flex;
      flex-direction: column;
      transition: height 1s;
    }
  }



  .menu-opened {
    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      animation-name: slidein;
      animation-duration: 1s;

      .user-info, .sign-in {
        flex-direction: column;
      }
    }
  }

  @keyframes slidein {
    from {
      height: 0;
    }

    to {
      height: 350px;
    }
  }
}
