@import "../../styles/_variables";

.sign-in, .user-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: $light-white-color;
  font-family: $main-font-family;
  font-weight: bold;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 25px;
    margin-top: auto;
  }

  p {
    font-size: 16px;
    margin-right: 20px;

    @media screen and (max-width: 1024px) {
      font-size: 20px;
      margin: 20px 0;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
