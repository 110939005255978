@import "../../styles/_variables";

.footer-wrapper {
  background: #25292F;
  color: #EBECEE;
  font-size: 16px;
  font-family: $main-font-family;
  font-weight: bold;

  a {
    color: #EBECEE;
  }

  .footer-container {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 48px;
    padding-bottom: 48px;
    flex-wrap: wrap;
    line-height: 1.5;

    img {
      max-width: 180px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 1300px) {
      width: 90%;
    }

    @media screen and (max-width: 1024px) {
      justify-content: flex-start;
    }

    h2 {
      color: $main-light-color;
    }

    h3 {
      color: $secondary-dark-color;
    }
  }

  .footer-description {
    width: 464px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 10px;
    }
  }

  .footer-explore, .footer-contact {
    @media screen and (max-width: 1024px) {
      margin-right: 100px;
    }

    @media screen and (max-width: 500px) {
      margin-right: 30px;
    }
  }

  .footer-follow {
    @media screen and (max-width: 1024px) {
      margin-right: 30px;
    }
  }

  .footer-explore, .footer-contact, .footer-follow {
    width: 155px;

    @media screen and (max-width: 1024px) {
      width: max-content;
    }

    a, p {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .footer-description , .footer-explore, .footer-contact {
    display: flex;
    flex-direction: column;

    a, p {
      margin-bottom: 10px;
    }
  }

  .footer-border {
    border-bottom: 1px solid gray;
  }
}
